import type { ToolBase } from '../types';

export const toolsList: ToolBase[] = [
  {
    id: 'calculator',
    title: 'Calculator',
    description: 'Basic and scientific calculations'
  },
  {
    id: 'pythagorean',
    title: 'Pythagorean Calculator',
    description: 'Calculate triangle sides and angles'
  },
  {
    id: 'stopwatch',
    title: 'Stopwatch',
    description: 'Precise time measurement with lap tracking'
  },
  {
    id: 'age-calculator',
    title: 'Age Calculator',
    description: 'Calculate age and time differences'
  },
  {
    id: 'percentage-calculator',
    title: 'Percentage Calculator',
    description: 'Calculate percentages and ratios'
  },
  {
    id: 'currency-converter',
    title: 'Currency Converter',
    description: 'Convert between different currencies'
  },
  {
    id: 'frequency-converter',
    title: 'Frequency Converter',
    description: 'Convert between frequency units'
  },
  {
    id: 'beam-calculator',
    title: 'Beam Calculator',
    description: 'Analyze beam deflection and stress'
  },
  {
    id: 'binary-calculator',
    title: 'Binary Calculator',
    description: 'Binary and hex conversions'
  },
  {
    id: 'bmi-calculator',
    title: 'BMI Calculator',
    description: 'Calculate Body Mass Index'
  },
  {
    id: 'circuit-calculator',
    title: 'Circuit Calculator',
    description: 'Analyze electrical circuits'
  },
  {
    id: 'chemical-equations',
    title: 'Chemical Equations',
    description: 'Balance chemical equations'
  },
  {
    id: 'compound-interest',
    title: 'Compound Interest',
    description: 'Calculate compound interest and growth'
  },
  {
    id: 'differential-equations',
    title: 'Differential Equations',
    description: 'Solve differential equations'
  },
  {
    id: 'fourier-transform',
    title: 'Fourier Transform',
    description: 'Analyze signals in frequency domain'
  },
  {
    id: 'gear-calculator',
    title: 'Gear Calculator',
    description: 'Calculate gear ratios and forces'
  },
  {
    id: 'graphing-calculator',
    title: 'Graphing Calculator',
    description: 'Plot mathematical functions'
  },
  {
    id: 'laplace-calculator',
    title: 'Laplace Calculator',
    description: 'Solve Laplace transforms'
  },
  {
    id: 'loan-calculator',
    title: 'Loan Calculator',
    description: 'Calculate loan payments and interest'
  },
  {
    id: 'polynomial-calculator',
    title: 'Polynomial Calculator',
    description: 'Find roots of polynomials'
  },
  {
    id: 'prime-calculator',
    title: 'Prime Calculator',
    description: 'Check prime numbers and factorization'
  },
  {
    id: 'signal-analyzer',
    title: 'Signal Analyzer',
    description: 'Analyze signal characteristics'
  },
  {
    id: 'statistics-calculator',
    title: 'Statistics Calculator',
    description: 'Statistical analysis and visualization'
  },
  {
    id: 'thermodynamics-calculator',
    title: 'Thermodynamics Calculator',
    description: 'Calculate thermodynamic properties'
  },
  {
    id: 'unit-converter',
    title: 'Unit Converter',
    description: 'Convert between different units'
  },
  {
    id: 'tdee-calculator',
    title: 'TDEE Calculator',
    description: 'Calculate Total Daily Energy Expenditure'
  },
  {
    id: 'calorie-calculator',
    title: 'Calorie Calculator',
    description: 'Calculate BMR and calorie deficit targets'
  }
];