import React from 'react';
import { FunctionSquare } from 'lucide-react';
import type { Tool } from './types';
import { toolsList } from './tools/toolsList';

// Map the tools list to include the icon and lazy-loaded component
export const tools: Tool[] = toolsList.map(tool => ({
  ...tool,
  icon: FunctionSquare,
  component: React.lazy(() => import(`../pages/tools/${tool.id}.tsx`))
}));

// Helper function to map tool IDs to correct component paths
function getComponentPath(id: string): string {
  // Convert kebab-case to PascalCase
  return id.split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join('');
}