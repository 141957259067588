import React from 'react';
import type { RouteObject } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';

// Import all tool pages statically to ensure proper bundling
const Calculator = React.lazy(() => import('./pages/tools/calculator'));
const Pythagorean = React.lazy(() => import('./pages/tools/pythagorean'));
const Stopwatch = React.lazy(() => import('./pages/tools/stopwatch'));
const AgeCalculator = React.lazy(() => import('./pages/tools/age-calculator'));
const PercentageCalculator = React.lazy(() => import('./pages/tools/percentage-calculator'));
const CurrencyConverter = React.lazy(() => import('./pages/tools/currency-converter'));
const FrequencyConverter = React.lazy(() => import('./pages/tools/frequency-converter'));
const BeamCalculator = React.lazy(() => import('./pages/tools/beam-calculator'));
const BinaryCalculator = React.lazy(() => import('./pages/tools/binary-calculator'));
const BMICalculator = React.lazy(() => import('./pages/tools/bmi-calculator'));
const CircuitCalculator = React.lazy(() => import('./pages/tools/circuit-calculator'));
const ChemicalEquations = React.lazy(() => import('./pages/tools/chemical-equations'));
const CompoundInterest = React.lazy(() => import('./pages/tools/compound-interest'));
const DifferentialEquations = React.lazy(() => import('./pages/tools/differential-equations'));
const FourierTransform = React.lazy(() => import('./pages/tools/fourier-transform'));
const GearCalculator = React.lazy(() => import('./pages/tools/gear-calculator'));
const GraphingCalculator = React.lazy(() => import('./pages/tools/graphing-calculator'));
const LaplaceCalculator = React.lazy(() => import('./pages/tools/laplace-calculator'));
const LoanCalculator = React.lazy(() => import('./pages/tools/loan-calculator'));
const PolynomialCalculator = React.lazy(() => import('./pages/tools/polynomial-calculator'));
const PrimeCalculator = React.lazy(() => import('./pages/tools/prime-calculator'));
const SignalAnalyzer = React.lazy(() => import('./pages/tools/signal-analyzer'));
const StatisticsCalculator = React.lazy(() => import('./pages/tools/statistics-calculator'));
const ThermodynamicsCalculator = React.lazy(() => import('./pages/tools/thermodynamics-calculator'));
const UnitConverter = React.lazy(() => import('./pages/tools/unit-converter'));
const TDEECalculator = React.lazy(() => import('./pages/tools/tdee-calculator'));
const CalorieCalculator = React.lazy(() => import('./pages/tools/calorie-calculator'));

// Map components to their routes
const toolComponents: Record<string, React.LazyExoticComponent<() => JSX.Element>> = {
  'calculator': Calculator,
  'pythagorean': Pythagorean,
  'stopwatch': Stopwatch,
  'age-calculator': AgeCalculator,
  'percentage-calculator': PercentageCalculator,
  'currency-converter': CurrencyConverter,
  'frequency-converter': FrequencyConverter,
  'beam-calculator': BeamCalculator,
  'binary-calculator': BinaryCalculator,
  'bmi-calculator': BMICalculator,
  'circuit-calculator': CircuitCalculator,
  'chemical-equations': ChemicalEquations,
  'compound-interest': CompoundInterest,
  'differential-equations': DifferentialEquations,
  'fourier-transform': FourierTransform,
  'gear-calculator': GearCalculator,
  'graphing-calculator': GraphingCalculator,
  'laplace-calculator': LaplaceCalculator,
  'loan-calculator': LoanCalculator,
  'polynomial-calculator': PolynomialCalculator,
  'prime-calculator': PrimeCalculator,
  'signal-analyzer': SignalAnalyzer,
  'statistics-calculator': StatisticsCalculator,
  'thermodynamics-calculator': ThermodynamicsCalculator,
  'unit-converter': UnitConverter,
  'tdee-calculator': TDEECalculator,
  'calorie-calculator': CalorieCalculator,
};

// Create route objects for each tool
const toolRoutes: RouteObject[] = Object.entries(toolComponents).map(([id, Component]) => ({
  path: `tools/${id}`,
  element: (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component />
    </React.Suspense>
  ),
}));

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      ...toolRoutes
    ],
  }
];